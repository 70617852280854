/***** Simple elements, atoms *****/

.icon {
  fill: currentColor;
  max-height: 100%;
  max-width: 100%;
}
.icon-w {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.img.img-frame {
  line-height: 0;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
  image {
    width: 100%;
  }
}

.section-h {
  margin: 0 0 40px;
  @include max($lg) {
    margin-bottom: 20px;
  }
  h2,
  .h2 {
  }
}

.txt {
  h2,
  .h2 {
    margin-bottom: 30px;
    @include max($lg) {
      margin-bottom: 20px;
    }
    &:not(:first-child) {
      margin-top: 40px;
      @include max($lg) {
        margin-top: 30px;
      }
    }
  }
  h3,
  .h3 {
    margin-bottom: 20px;
    @include max($lg) {
      margin-bottom: 15px;
    }
    &:not(:first-child) {
      margin-top: 30px;
      @include max($lg) {
        margin-top: 20px;
      }
    }
  }
  h4,
  .h4 {
    margin-bottom: 30px;
    @include max($lg) {
      margin-bottom: 20px;
    }
    &:not(:first-child) {
      margin-top: 30px;
      @include max($lg) {
        margin-top: 20px;
      }
    }
  }

  p {
    margin: 0 0 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    text-decoration: underline;
    &:hover {
      color: $primaryhover;
    }
  }

  ul {
    margin: 1.5em 0;
    padding: 0 0 0 2em;
    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  ol {
    li {
    }
  }

  table {
  }
  .link-arrow {
    margin: 0 20px 0 2px;
  }

  .btns {
    margin: 50px 0;
    @include max($lg) {
      margin: 30px 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

p {
  a {
    text-decoration: underline;
    &:hover {
      color: $primaryhover;
    }
  }
}

.link-list {
  ul {
    @include bordered-list;
  }
}

.link-arrow {
  padding-right: 20px;
  position: relative;
  font-weight: bold;
  &:after {
    content: "";
    width: 10px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="33" viewBox="0 0 590.01 956.84"><path d="M111.51,956.84,590,478.34,111.67,0,0,111.63,366.86,478.46,0,845.32Z" fill="%230057B7"/></svg>')
      50% 50% no-repeat;
    background-size: 10px 16px;
    transition: all 0.2s;
  }
  &:hover {
    &:after {
      opacity: 0.8;
      right: -5px;
    }
  }
}

.load-more {
  display: flex;
  justify-content: center;
  .btn {
    display: flex;
    align-items: center;
    padding: 9px 48px;
    .icon-angle-down {
    }
    span {
      margin: 0 8px;
    }
  }
}

.back {
  margin: 30px 0 50px;
  @include max($lg) {
    margin: 20px 0 30px;
  }
}
