/* Color */
$primary: #0057B7;
$primaryhover: lighten($primary, 7%);
$secondary: #F7C6A0;
$green: #006C5B;
$greyf: #F6F5F9;
$greye: #E1E5EE;

$font-heading: 'Roboto', sans-serif;
$font-basic: 'Roboto', sans-serif;

/* Size */
$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
