/***** Default and helpers *****/

@import "mixins";
@import "variables";

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("../fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-medium-webfont.woff2") format("woff2"),
    url("../fonts/roboto-medium-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  overflow-x: hidden;
  color: $primary;
  font-family: $font-basic;
  font-size: 17px;
  letter-spacing: 0.02em;
  height: 100%;
  line-height: 1.5;
  @include max($lg) {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

input[type="submit"],
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input,
textarea,
label {
  font-family: $font-basic;
}
a,
button,
input,
textarea,
select {
  outline: none;
}

input,
textarea {
  color: $primary;
  @include placeholder {
    opacity: 0.7;
    color: $primary;
  }
  &:focus {
    @include placeholder {
      opacity: 0.4;
    }
  }
}

textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
}

a {
  color: $primary;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    color: $primaryhover;
  }
}

h1,
h2,
h3,
h4 {
  font-family: $font-heading;
  margin: 0;
}

h1,
.h1 {
  font-size: 80px;
  font-weight: 900;
  line-height: 1.05;
  @include max($xxl) {
    font-size: 64px;
  }
  @include max($lg) {
    font-size: 40px;
  }
  @include max($xxs) {
    font-size: 32px;
  }
}
h2,
.h2 {
  font-size: 31px;
  line-height: 1.1;
  text-transform: uppercase;
  @include max($lg) {
    font-size: 28px;
  }
}
h3,
.h3 {
  line-height: 1.1;
  font-size: 24px;
  @include max($lg) {
    font-size: 20px;
  }
}
h4,
.h4 {
  line-height: 1.1;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
}

p {
  margin: 0 0 1em;
}

b,
strong {
}

img {
  max-width: 100%;
  height: auto;
}
