@mixin cf() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin min($device-width) {
  @media screen and (min-width: $device-width) {
    @content;
  }
}

@mixin max($device-width) {
  @media screen and (max-width: $device-width - 1) {
    @content;
  }
}
@mixin minmax($device-width1, $device-width2) {
  @media screen and (min-width: $device-width1) and (max-width: $device-width2 - 1) {
    @content;
  }
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

%text-heading {
  font-family: $font-heading;
  letter-spacing: 1.1;
}

@mixin bordered-list {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    display: block;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    a {
      position: relative;
      padding: 0 0 3px;
      font-weight: bold;
      display: inline-block;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $primary;
        transition: all 0.2s;
      }
      &:hover {
        &:after {
          background: $primaryhover;
        }
      }
    }
  }
}

@mixin corner-left {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 30px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.08 420.26"><path d="M33.08,420.26V0L0,420.26Z" fill="%23F6F5F9" /></svg>') 100% 0% no-repeat;
    background-size: cover;
  }
}