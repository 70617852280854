/*** Deal ***/

.section-deal {
  padding: 80px 0;
  @include max($lg) {
    padding: 30px 0;
  }
  .deal-info {
    padding-right: 20px;
    @include max($lg) {
      padding: 0 0 30px;
    }
  }
  .deal-r {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }
  .deal-experts {
    margin-bottom: 40px;
    .block-h {
      margin-bottom: 24px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    .block-body {}
    .expert-list {
      gap: 45px 18px;
      padding-left: 113px;
      @include max($xxxl) {
        padding: 0;
        gap: 30px 18px;
      }
      .expert-item {
        width: 247px;
        flex: 0 0 247px;
        @include max($xxl) {
          width: calc(50% - 9px);
          flex: 0 0 calc(50% - 9px);
        }
        @include max($lg) {
          width: 247px;
          flex: 0 0 247px;
        }
        @include max($sm) {
          width: 100%;
          flex: auto;
        }
      }
    }
  }
  .deal-related {
    .block-h {
      margin-bottom: 24px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    .block-body {
      background: $greyf;
      padding: 60px 40px;
      @include max($lg) {
        padding: 20px 15px;
      }
      .link-list {}
    }
  }
} 

/*** Post ***/

.section-post {
  padding: 80px 0 0;
  @include max($lg) {
    padding: 30px 0 0;
  }
  .post-txt {}
}