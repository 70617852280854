/*** Event ***/

.section-event {
  padding: 100px 0;
  @include max($lg) {
    padding: 30px 0;
  }
  .event-info {
    margin-bottom: 50px;
    @include max($lg) {
      margin-bottom: 30px;
    }
  }
  .btns {
    @include max($lg) {
      margin-bottom: 30px;
    }
  }

  .event-bnr {
    margin-bottom: 60px;
    @include max($lg) {
      margin-bottom: 30px;
    }
    @include max($sm) {
      margin: 0 -15px 30px;
    }
  }
  .event-experts {
    margin-bottom: 50px;
    @include max($lg) {
      margin-bottom: 30px;
    }
    .block-h {
      margin-bottom: 24px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    .block-body {}
    .expert-list {
      gap: 45px 18px;
      padding-left: 113px;
      @include max($xxxl) {
        padding: 0;
        gap: 30px 18px;
      }
    }
    .expert-item {
      width: 247px;
      flex: 0 0 247px;
      @include max($xxl) {
        width: calc(50% - 9px);
        flex: 0 0 calc(50% - 9px);
      }
      @include max($lg) {
        width: 247px;
        flex: 0 0 247px;
      }
      @include max($sm) {
        width: 100%;
        flex: auto;
      }
      .item-photo {
        img {}
      }
      .item-body {}
      .item-name {}
      .item-position {}
      .item-country {}
      .item-email {}
      .item-phone {}
    }
  }
  .event-agenda {
    .block-h {
      margin-bottom: 24px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    .block-body {
      background: $greyf;
      padding: 40px 40px;
      @include max($lg) {
        padding: 20px 15px;
      }
    }
  }
}