/***** Homepage *****/

.section-homebnr {
  .homebnr-i {
    position: relative;
    padding-top: 34.9219%;

    @include max($md) {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 0 -15px;
      overflow: hidden;
    }
  }
  .homebnr-info {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40%;
    padding: 30px 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @include max($xxxl) {
      padding: 30px 5%;
    }
    @include max($md) {
      order: 2;
      position: relative;
      display: block;
      text-align: center;
      // background: $primary;
      background: $greyf;
      // color: #fff;
      padding: 30px 15px;
      width: auto;
      height: auto;
      margin: 10px 0 0;
      &:after {
        @include max($md) {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("../images/home-bnr-r.jpg") 50% 50% no-repeat;
          background-size: cover;
          opacity: 0.3;
        }
      }
    }
    .homebnr-txt {
      margin-bottom: 100px;
      position: relative;
      z-index: 1;
      @include max($xxxl) {
        margin-bottom: 4vw;
      }
      @include max($md) {
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 1.5em;
        @include max($xxxl) {
          margin-bottom: 1em;
        }
        @include max($md) {
          // margin-bottom: 0.5em;
        }
      }
    }
    .homebnr-btn {
      position: relative;
      z-index: 1;
      .btn {
        // @include max($md) {
        //   background: $greye;
        //   color: $primary;
        //   &:hover {
        //     background: $greyf;
        //   }
        // }
      }
    }
  }
  .homebnr-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: url("../images/home-bnr.jpg") 0 0 no-repeat;
    background-size: cover;
    @include max($md) {
      position: relative;
      order: 1;
      background-size: 170% auto;
      // padding-top: 40%;
    }
    .homebnr-bg-i {
      position: relative;
      padding: 24px 0 0;
      @include max($md) {
        height: 55vw;
        padding: 4vw 0 0;
      }
    }
    .homebnr-txt {
      z-index: 1;
      transition: all 1s;
      background-size: contain !important;
      &.homebnr-txt--1 {
        width: 288px;
        height: 126px;
        margin-bottom: 10px;
        margin-left: 5%;
        background: url("../images/home-bnr-txt-1.png") 0 0 no-repeat;
        @include max($xxl) {
          width: 20vw;
          height: 9vw;
          margin-bottom: 0.5vw;
        }
        @include max($md) {
          width: 35vw;
          height: 15vw;
        }
      }
      &.homebnr-txt--2 {
        width: 435px;
        height: 126px;
        margin-bottom: 10px;
        margin-left: 15%;
        background: url("../images/home-bnr-txt-2.png") 0 0 no-repeat;
        @include max($xxl) {
          width: 30vw;
          height: 9vw;
          margin-bottom: 0.5vw;
        }
        @include max($md) {
          width: 50vw;
          height: 15vw;
        }
      }
      &.homebnr-txt--3 {
        width: 415px;
        height: 126px;
        margin-left: 7%;
        background: url("../images/home-bnr-txt-3.png") 0 0 no-repeat;
        @include max($xxl) {
          width: 29vw;
          height: 9vw;
        }
        @include max($md) {
          width: 50vw;
          height: 15vw;
        }
      }
      &.homebnr-anim {
        opacity: 0;
        margin-left: 0%;
      }
      &.homebnr-anim-left {
        opacity: 0;
        margin-left: 10%;
      }
    }
  }
}

.section-infoblocks {
  padding: 80px 0 57px;

  @include max($lg) {
    padding: 40px 0 20px;
  }
  .infoblock-list {
    display: flex;
    margin: 0 -11px;
    align-items: stretch;
    @include max($xl) {
      margin: 0 -5px;
    }
    @include max($lg) {
      flex-wrap: wrap;
    }
  }
  .infoblock-w {
    flex: 0 0 25%;
    padding: 0 11px;
    display: flex;
    align-items: stretch;
    @include max($xl) {
      padding: 0 5px;
    }
    @include max($lg) {
      flex: 0 0 50%;
      padding: 0 5px 10px;
    }
    @include max($sm) {
      flex: 0 0 100%;
    }
  }
  .infoblock {
    width: 100%;
    padding: 48px 27px 80px 21px;
    position: relative;
    @include max($lg) {
      padding: 20px 20px 60px;
    }
    @include max($sm) {
      min-height: 200px;
    }
    &.infoblock-1 {
      background: $greye;
    }
    &.infoblock-2 {
      background: $secondary;
    }
    &.infoblock-3 {
      background: $primary;
      color: #fff;
    }
    &.infoblock-4 {
      background: $green;
      color: #fff;
    }
    .infoblock-title {
      font-size: 31px;
      line-height: 1.1;
      font-weight: bold;
      margin-bottom: 30px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    .infoblock-txt {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 1.3;
    }
    .infoblock-link {
      position: absolute;
      bottom: 22px;
      right: 28px;

      @include max($lg) {
        bottom: 10px;
        right: 10px;
      }
      a {
        width: 30px;
        height: 42px;
        padding: 5px;
        color: #fff;
        display: block;
        line-height: 0;
        @include max($lg) {
          width: 26px;
          height: 36px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      .icon {
      }
    }
  }
}

.section-services {
  padding: 30px 0;
  .section-h {
    margin-bottom: 80px;
    @include max($lg) {
      margin-bottom: 30px;
    }
    h2 {
    }
  }
  .section-body {
  }
}

.expand-item {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .item-top {
    display: flex;
    align-items: stretch;
    height: 60px;
    @include max($xxs) {
      height: 50px;
      margin: 0 0 0 -15px;
    }
    .item-toggler {
      width: 66px;
      flex: 0 0 66px;
      line-height: 0;
      margin-right: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;
      @include max($xxs) {
        width: 50px;
        flex: 0 0 50px;
        margin-right: 10px;
      }
      &:hover {
        background: rgba($primary, 0.1);
      }
      .icon {
        width: 15px;
        height: 15px;
        transition: all 0.2s;
      }
    }
    .item-h {
      text-transform: uppercase;
      font-weight: bold;
      padding: 10px 0;
      line-height: 1.2;
      align-self: center;
    }
  }
  &:nth-child(2n + 1) {
    .item-top {
      background: $greyf;
    }
    &.is-active {
      background: $greyf;
    }
  }
  &:nth-child(2n) {
    .item-top {
      background: $greye;
    }
    &.is-active {
      background: $greye;
    }
  }
  .item-content {
    display: none;
    padding: 40px 0 75px;
    @include max($lg) {
      padding: 30px 0;
    }
  }
  .item-info {
    margin-bottom: 80px;
    padding: 0 30px 0 85px;
    @include max($lg) {
      padding: 0;
      margin-bottom: 40px;
    }
    .link-list {
      margin-bottom: 30px;
    }
    .txt {
      p {
      }
      .more {
      }
    }
  }
  .experts {
    .experts-h {
      font-size: 31px;
      font-weight: bold;
      line-height: 1.1;
      margin-bottom: 35px;
      @include max($lg) {
        font-size: 28px;
      }
    }
    .expert-list {
      .expert-item {
        width: calc(25% - 16px);
        @include max($lg) {
          width: calc(50% - 11px);
          flex: 0 0 calc(50% - 11px);
        }
        @include max($sm) {
          width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
  &.is-active {
    .item-top {
      .item-toggler {
        background: $primary;
        color: #fff;
        .icon {
          transform: rotate(45deg);
        }
      }
    }
    .item-content {
      display: block;
    }
  }
}

.expert-list {
  display: flex;
  flex-wrap: wrap;
  gap: 60px 21px;
  @include max($lg) {
    gap: 30px 21px;
  }
  @include max($sm) {
    flex-direction: column;
  }
  .expert-item {
    width: calc(25% - 16px);
    @include max($lg) {
      width: calc(50% - 11px);
      flex: 0 0 calc(50% - 11px);
    }
    @include max($sm) {
      width: 100%;
      flex: 0 0 100%;
    }
    .item-photo {
      line-height: 0;
      margin-bottom: 20px;
      @include max($sm) {
        margin-bottom: 10px;
      }
      img {
        max-width: 100%;
        @include max($xs) {
          width: 100%;
        }
      }
    }
    .item-body {
    }
    .item-name {
      font-weight: 900;
      text-transform: uppercase;
    }
    .item-position {
    }
    .item-country {
      text-transform: uppercase;
    }
    .item-email {
    }
    .item-phone {
    }
  }
}

.section-awards {
  padding: 50px 0;
  @include max($lg) {
    padding: 30px 0;
  }
  .section-h {
    margin-bottom: 40px;
    h2 {
    }
  }
  .section-body {
  }
  .awards-swiper {
    .swiper-pagination {
      margin-top: 16px;
    }
  }
  .award-card {
    display: flex;
    align-items: stretch;
    min-height: 183px;
    .card-img {
      flex: 0 0 220px;
      background: $greye;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      @include max($sm) {
        flex: 0 0 35%;
        padding: 10px;
      }
      img {
      }
    }
    .card-txt {
      background: $greyf;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 40px 20px 60px;
      letter-spacing: 0;
      position: relative;
      flex: 1;
      @include corner-left;
      @include minmax($lg, $xl) {
        padding: 20px 30px 20px 40px;
      }
      @include max($sm) {
        padding: 20px;
      }
      span {
      }
    }
  }
}

.section-testimonials {
  padding: 50px 0 80px;
  @include max($lg) {
    padding: 30px 0 50px;
  }
  .testimonials-h {
    margin-bottom: 40px;
    h2 {
    }
  }
  .section-body {
  }
  .testimonials-swiper {
    .swiper-pagination {
      margin-top: 16px;
    }
  }
  .testimonial-card {
    display: flex;
    align-items: stretch;
    min-height: 178px;
    @include max($xxs) {
      font-size: 14px;
    }
    .card-txt {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      background: $greye;
      padding: 20px 65px;
      @include minmax($lg, $xl) {
        padding: 20px 40px;
      }
      @include max($sm) {
        padding: 20px 15px;
      }
      span {
      }
    }
    .card-info {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $greyf;
      padding: 20px 40px 20px 60px;
      @include corner-left;
      @include minmax($lg, $xl) {
        padding: 20px 30px 20px 40px;
      }
      @include max($sm) {
        padding: 20px 15px;
      }
      span {
      }
    }
  }
  .back {
    padding-top: 50px;
  }
}
