/*** Experts ***/

.section-experts {
  padding: 80px 0 110px;
  @include max($lg) {
    padding: 30px 0;
  }
  .section-h {
    margin-bottom: 15px;
  }
  .experts-filter {
    .experts-filter-t {
      margin-bottom: 70px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include max($lg) {
        margin-bottom: 30px;
      }
      .label {
        margin-right: 20px;
        text-transform: uppercase;
        @include max($lg) {
          margin: 0 0 10px;
          flex: 0 0 100%;
        }
      }
      .experts-filter-t-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span {
          text-transform: uppercase;
          cursor: pointer;
          display: block;
          line-height: 19px;
          padding: 1px 5px 2px;
          min-width: 22px;
          margin-right: 1px;
          text-align: center;
          transition: all 0.2s;
          &:hover {
            background: $greyf;
          }
          &.all {
            margin-right: 20px;
          }
          &.is-active {
            background: $primary;
            color: #fff;
          }
        }
      }
    }
    .experts-filter-b {
      margin-bottom: 45px;
      .select-container {
        @include max($lg) {
          margin-bottom: 10px;
        }
      }
      .search-block {
        position: relative;
        @include max($lg) {
          margin-top: 5px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -15px;
          left: 0;
          width: 100%;
          height: 5px;
          background: $primary;
          @include max($lg) {
            display: none;
          }
        }
      }
    }
  }
  
  .experts-groups {
    .experts-items {}
  }
}