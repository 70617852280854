/*** Expert ***/

.section-expertinfo {
  padding: 80px 0 50px;
  @include max($lg) {
    padding: 30px 0;
  }
  .expertinfo-block {
    .block-h {
      margin-bottom: 24px;
      @include max($lg) {
        margin-bottom: 20px;
      }
      h2, .h2 {}
    }
    .block-body {}
    &.expertinfo-contacts {
      margin-bottom: 80px;
      font-size: 21px;
      @include max($lg) {
        margin-bottom: 40px;
        font-size: 17px;
      }
      
      .block-body {
        background: $greyf;
        padding: 50px 50px 50px;
        @include max($lg) {
          padding: 20px 15px;
        }
        > div {
          margin-bottom: 3px;
        }
      }
      .expertinfo-email {
        a {}
      }
      .expertinfo-phone {
        a {}
      }
      .expertinfo-social {
        margin-top: 30px;
        display: flex;
        align-items: center;
        gap: 15px;
        .social-item {
          a {
            display: flex;
            align-items: center;
            .social-icon {
              width: 34px;
              height: 34px;
              flex: 0 0 34px;
              background: $primary;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              transition: all 0.2s;
              .icon {
                &.icon-linkedin {
                  width: 16px;
                  height: 15px;
                }
                &.icon-user {
                  width: 13px;
                  height: 16px;
                }
              }
            }
            .social-txt {
              font-weight: bold;
              margin-left: 4px;
            }
            &:hover {
              .social-icon {
                background: $primaryhover;
              }
            }
          }
        }
      }
    }
    &.expertinfo-about {
      @include max($lg) {
        margin-bottom: 40px;
      }
      .block-body {}
      .txt {}
    }
    &.expertinfo-practice {
      .block-body {
        &.no-block-h {
          margin-top: 58px;
          @include max($lg) {
            margin-top: 0;
          }
        }
      }
      .practice-list {
        background: $greyf;
        padding: 50px 40px 60px;
        margin-bottom: 10px;
        @include max($lg) {
          padding: 20px 15px;
        }
        ul {
          li {
            a {}
          }
        }
      }
      .expand-item {
        margin: 0 0 10px;
        // @include max($xxxl) {
        //   margin: 0 -20px 10px;
        // }
        @include max($lg) {
          margin: 0 -15px 10px;
        }
        .container {
          padding: 0;
          @include max($lg) {
            padding: 0 15px;
          }
        }
        .item-top {
        }
        .item-content {
          padding: 40px 20px 60px 85px;
          @include max($lg) {
            padding: 20px 0 20px 85px;
          }
          @include max($sm) {
            padding: 20px 0;
          }
          
        }
        &.is-active {
         
        }
      }
    }
  }
}