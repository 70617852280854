/*** Single Service ***/

.section-service {
  padding: 70px 0 50px;
  @include max($lg) {
    padding: 30px 0;
  }
  .service-info {
    padding: 0 0 140px;
    @include max($lg) {
      padding: 0 0 40px;
    }
    .txt {
      @include max($lg) {
        margin-bottom: 40px;
      }
    }
    .service-info-r {
      padding-left: 115px;
      @include max($xxxl) {
        padding-left: 0;
      }
    }
    h2 {
      margin-bottom: 75px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    .service-contact-list {
      .col-md-6 {
        &:first-child {
          @include max($md) {
            margin-bottom: 30px;
          }
        }
      }
    }
    .service-contact-item {
      &:not(:last-child) {
        margin-bottom: 50px;
        @include max($lg) {
          margin-bottom: 30px;
        }
      }
      .item-name {
        font-weight: 900;
        text-transform: uppercase;
      }
      .item-position {
      }
      .item-location {
        text-transform: uppercase;
      }
      .item-email {
      }
      .item-phone {
      }
      a {
        &:hover {
        }
      }
    }
  }
  .back {
    padding-top: 50px;
    @include max($lg) {
      padding-top: 30px;
    }
  }
}

.publications {
  padding: 40px 0 15px;
  @include max($lg) {
    padding: 0;
  }
  .publication-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 21px;
    @include max($lg) {
      gap: 30px 21px;
    }
    .publication-item {
      width: calc(25% - 16px);
      flex: 0 0 calc(25% - 16px);
      @include max($lg) {
        width: calc(50% - 11px);
        flex: 0 0 calc(50% - 11px);
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      .item-title {
        font-size: 24px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 25px;
        @include max($lg) {
          margin-bottom: 10px;
          font-size: 18px;
        }
        a {
        }
      }
      .item-date {
      }
    }
  }
  .load-more {
    margin-top: 80px;
    @include max($lg) {
      margin-top: 30px;
    }
  }
}

.recognitions {
  padding: 40px 0 15px;
  @include max($lg) {
    padding: 0;
  }
  .recognition-item {
    .item-title {
      font-weight: 900;
      margin-bottom: 3px;
    }
    .item-txt {
    }
  }
}

.section-posts {
  .posts-view {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 36px;
    gap: 30px;
    @include max($lg) {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
    @include max($sm) {
      display: none;
    }
    .posts-view-btn {
      display: flex;
      align-items: center;
      padding: 18px 0;
      position: relative;
      cursor: pointer;
      transition: all 0.2s;
      @include max($lg) {
        padding: 10px 0;
      }
      &:hover {
        color: $primaryhover;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $primary;
        opacity: 0;
        visibility: hidden;
      }
      &.is-active {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
      .icon {
        width: 42px;
        height: 30px;
        flex: 0 0 42px;
        @include max($lg) {
          width: 32px;
          flex: 0 0 32px;
        }
      }
      span {
        text-transform: uppercase;
        margin-left: 11px;
      }
    }
  }
  .posts-filter {
    padding-bottom: 45px;
    .select-container {
      @include max($lg) {
        margin-bottom: 10px;
      }
    }
    .filter-search {
      margin-top: 15px;
      @include max($lg) {
        margin-top: 5px;
      }
    }
  }
  .posts-featured-item {
    padding: 0;
    position: relative;
    overflow: hidden;
    background: $greyf;
    .item-i {
      display: flex;
      align-items: center;
      min-height: 371px;
      @include max($xxxl) {
        min-height: 0;
      }
      @include max($md) {
        // margin: 0 -7vw 0 -15px;

        padding: 30px 0;
        margin: 0;
      }
      @include max($sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .item-body {
      width: auto;
      flex: 1;
      padding: 50px 50px 45px 0;
      @include max($xxxl) {
        padding: 40px 20px;
      }
      @include max($xxl) {
        width: 60%;
        flex: 0 0 60%;
      }
      @include max($lg) {
        // width: 50%;
        // flex: 0 0 50%;
        padding: 30px 15px;
      }
      @include max($md) {
        // order: 2;
        padding: 0;
        flex: 1;
        width: 100%;
      }
      @include max($sm) {
        order: 2;
      }
      .item-title {
        font-size: 31px;
        font-weight: bold;
        margin-bottom: 8px;
        line-height: 1.4;
        @include max($lg) {
          font-size: 24px;
          line-height: 1.2;
        }
        @include max($sm) {
          font-size: 20px;
        }
        a {
        }
      }
      .item-date {
      }
      .item-brief {
        margin-top: 10px;
        @include max($sm) {
          display: none;
        }
      }
      .item-link {
        font-weight: bold;
        margin-top: 10px;
        @include max($md) {
          display: none;
        }
        a {
        }
      }
    }
    .img {
      width: 506px;
      flex: 0 0 506px;
      align-self: stretch;
      @include max($xxl) {
        width: 40%;
        flex: 0 0 40%;
      }
      @include max($xxl) {
        margin-right: -20%;
        width: 60%;
        flex: 0 0 60%;
      }
      @include max($lg) {
        width: 80%;
        flex: 0 0 80%;
      }
      @include max($md) {
        // order: 1;
        flex: 0 0 30%;
        width: 30%;
        margin: 0 0 0 10px;
      }
      @include max($sm) {
        width: 100%;
        max-width: 400px;
        flex: 1;
        margin: 0 0 15px;
        order: 1;
      }
      img {
        @include max($md) {
          width: 124vw;
          height: 108vw;
          margin: 0 -10vw;
          flex: auto;
        }
      }
    }
  }
  .post-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 21px;
    @include max($lg) {
      gap: 30px 21px;
    }
    @include max($sm) {
      gap: 0;
    }
    .post-item {
      width: calc(25% - 16px);
      flex: 0 0 calc(25% - 16px);
      @include max($lg) {
        width: calc(50% - 11px);
        flex: 0 0 calc(50% - 11px);
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
        padding: 30px 0;
      }
      .item-img {
        margin-bottom: 40px;

        @include max($lg) {
          margin-bottom: 10px;
        }
        @include max($sm) {
          width: 100%;
          max-width: 400px;
          margin: 0 0 15px;
        }
        // image {
        //   mix-blend-mode: hard-light;
        // }
        a {
          image,
          path {
            filter: url(#grayscale-filter);
            transition: filter 1s;
          }
          &:hover {
            image,
            path {
              filter: none;
            }
          }
        }
      }
      .item-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 1.2;
        @include max($lg) {
          font-size: 18px;
          margin-bottom: 10px;
        }
        a {
        }
      }
      .item-date {
      }
      .item-brief {
        display: none;
        margin-top: 10px;
      }
      &:nth-child(2n) {
        position: relative;
        &:after {
          content: "";
          z-index: -1;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100vw;
          background: $greyf;
          display: none;
          @include max($sm) {
            display: block;
          }
        }
      }
    }
  }
  .load-more {
    margin-top: 60px;
    @include max($lg) {
      margin-top: 30px;
    }
  }

  &.section-posts-simple {
    padding: 80px 0 60px;
    @include max($lg) {
      padding: 40px 0 30px;
    }
    .post-list {
      .post-item {
        &:first-child {
          padding-top: 0;
        }
      }
    }
  }
  &.section-posts-advanced {
    padding: 28px 0 110px;
    @include max($lg) {
      padding: 30px 0;
    }
    .post-list {
      padding: 85px 0 0;
      @include max($lg) {
        padding: 30px 0 0;
      }
      @include max($sm) {
        padding: 0;
      }
      &.is-list {
        padding: 50px 0 0;
        flex-direction: column;
        gap: 25px;
        @include max($lg) {
          padding: 0 0 0;
          gap: 0;
        }
        .post-item {
          width: 100%;
          display: flex;
          position: relative;
          padding: 32px 0;
          @include max($lg) {
            padding: 30px 0;
          }
          @include max($sm) {
            flex-direction: column;
            align-items: flex-start;
          }
          .item-img {
            flex: 0 0 301px;
            margin: 0 25px 0 0;
            @include max($lg) {
              margin: 0 10px 0 0;
              flex: 0 0 30%;
              width: 30%;
            }
            @include max($sm) {
              flex: 1;
              width: 100%;
              max-width: 400px;
              margin: 0 0 15px;
            }
          }
          .item-body {
            flex: 1;
          }
          .item-title {
            font-size: 31px;
            margin-bottom: 10px;
            @include max($lg) {
              font-size: 24px;
            }
            @include max($sm) {
              font-size: 20px;
            }
          }
          .item-brief {
            display: block;
            @include max($sm) {
              display: none;
            }
          }
          &:nth-child(2n) {
            &:after {
              content: "";
              display: block;
              z-index: -1;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 100vw;
              background: $greyf;
            }
            .item-img {
              order: 2;
              margin: 0 0 0 25px;
              @include max($lg) {
                margin: 0 0 0 10px;
              }
              @include max($sm) {
                order: 1;
                margin: 0 0 15px;
              }
            }
            .item-body {
              order: 1;
              @include max($sm) {
                order: 2;
              }
            }
          }
        }
      }
    }
  }
}
