/***** Header *****/

.header {
  padding: 83px 0 69px;
  background: #fff;
  transition: all 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @include max($xxxl) {
    padding: 30px 0;
  }
  @include max($lg) {
    padding: 15px 0;
  }
  .header-i {
    display: flex;
    align-items: center;
  }
  .logo {
    margin-right: 85px;
    flex: 0 0 200px;
    @include max($xxxl) {
      margin-right: 40px;
      flex: 0 0 150px;
    }
    @include max($lg) {
      flex: 0 0 110px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
      width: 100%;
      object-fit: contain;
      @include max($xxxl) {
        height: 44px;
      }
      @include max($lg) {
        height: 32px;
      }
    }
  }
  .header-r {
    flex: 1;
    display: flex;
    align-items: center;
    @include max($lg) {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 66px;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      padding-top: 20px;
      transition: all 0.2s;
      z-index: -1;
      flex-direction: column;
      align-items: stretch;
    }
  }
  .header-menu-search {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 106px;
    flex: 1;
    @include max($lg) {
      display: block;
      padding: 0;
      flex: 0 1 auto;
    }
  }
  .header-menu {
    margin-right: 30px;
    @include max($lg) {
      margin: 0;
    }
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      gap: 36px;
      @include max($xxxl) {
        gap: 30px;
      }
      @include max($xl) {
        gap: 24px;
      }
      @include max($lg) {
        display: block;
      }
      li {
        list-style: none;
        font-size: 20px;
        text-transform: uppercase;
        @include max($xxxl) {
          font-size: 18px;
        }
        @include max($xl) {
          font-size: 16px;
        }
        a {
          padding: 5px 0;
          position: relative;
          @include max($lg) {
            padding: 10px 15px;
            display: block;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $primaryhover;
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s;
            @include max($lg) {
              display: none;
            }
          }
          &:hover {
            &:after {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        span {
          position: relative;
          padding: 5px 0;
          font-weight: bold;
          cursor: default;
          @include max($lg) {
            padding: 5px 15px;
            display: block;
          }
        }
      }
    }
  }
  .header-search {
    margin-left: auto;
    position: absolute;
    right: 0;
    top: -4px;
    width: 106px;
    @include max($lg) {
      display: block;
      right: auto;
      top: auto;
      width: auto;
      margin: 10px 15px;
      position: relative;
    }
    .search-input {
      @include placeholder {
        opacity: 0;
        @include max($lg) {
          opacity: 0.7;
        }
      }
    }
    &.is-active {
      width: 100%;
      @include max($lg) {
        width: auto;
      }
      .search-input {
        @include placeholder {
          opacity: 0.5;
        }
      }
    }

  }

  .header-lang {
    margin-left: 15px;
    padding: 0 5px;
    position: relative;
    @include max($lg) {
      margin: 20px 15px 20px 10px;
      display: flex;
      align-items: flex-start;
      padding: 0;
      flex: auto;
    }
    .lang-curr {
      position: relative;
      padding-right: 21px;
      cursor: pointer;
      @include max($lg) {
        padding: 0 5px;
        font-weight: bold;
        // background: $greyf;
        margin-right: 10px;
      }
      &:hover {
        opacity: 0.9;
      }
      span {
        font-size: 20px;
        text-transform: uppercase;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 17px;
        height: 10px;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="37" height="10" viewBox="0 0 958.99 591.34"><path d="M0,111.76,479.58,591.34,959,111.92,847.11,0,479.45,367.69,111.76,0Z" fill="%23F7C6A0"/></svg>') 50% 50% no-repeat;
        transition: all 0.2s;
        @include max($lg) {
          display: none;
        }
      }
    }
    .lang-options {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background: #f8f8fa;
      transition: all 0.2s;
      padding: 5px 0;
      @include max($lg) {
        opacity: 1;
        visibility: visible;
        position: relative;
        top: auto;
        width: auto;
        background: none;
        padding: 0;
      }
      &.is-active {
        opacity: 1;
        visibility: visible;
      }
      ul {
        padding: 0;
        margin: 0;
        @include max($lg) {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        li {
          display: block;
          list-style: none;
          a {
            display: block;
            padding: 0 5px;
            font-size: 20px;

            &:hover {
              background: #f2f2f4;
            }
          }
        }
      }
    }
    &.is-active {
      background: #f8f8fa;
      .lang-curr {
        &:after {
          transform: translateY(-50%) rotate(180deg);

        }
      }
      .lang-options {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .header-toggler {
    display: none;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    transition: all 0.2s;
    @include max($lg) {
      display: flex;
    }
    &:hover {
      opacity: 0.8;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }

  &.is-menu {
    padding: 15px 0 !important;
    .header-r {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }
  }
}

.main {
  padding-top: 210px;
  transition: all 0.2s;
  @include max($xxxl) {
    padding-top: 104px;
  }
  @include max($lg) {
    padding-top: 66px;
  }
}

body.is-scrolled {
  .header {
    padding: 10px 0;
    box-shadow: 0 0 10px rgba(#7b95b1, 0.2);
    @include max($lg) {
      padding: 10px 0;
    }
  }
  // .main {
  //   padding-top: 78px;
  // }
}