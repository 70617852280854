/***** Careers *****/

.section-hero {
  padding: 0;
  position: relative;
  overflow: hidden;
  .hero-i {
    background: linear-gradient(to right, #f6f5f9, rgba(#fff, 0));
    display: flex;
    align-items: flex-end;
    min-height: 447px;
    @include max($xxxl) {
      min-height: 0;
    }
    @include max($md) {
      margin: 0 -7vw 0 -15px;
    }
    @include max($sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .hero-info {
    width: auto;
    flex: 1;
    padding: 2% 6% 6.5% 6%;
    position: relative;
    @include max($xxxl) {
      padding: 40px 20px;
    }
    @include max($xxl) {
      width: 60%;
      flex: 0 0 60%;
    }
    @include max($lg) {
      // width: 50%;
      // flex: 0 0 50%;
      padding: 40px 15px;
    }
    @include max($sm) {
      width: 100vw;
      flex: auto;
      min-height: 300px;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(40deg, #f6f5f9 20%, rgba(#fff, 0) 80%);
      display: none;
      opacity: 1;
      z-index: -1;
      @include max($sm) {
        display: block;
      }
    }
    h1,
    .h1 {
      margin-bottom: 50px;
      line-height: 1;
      @include max($xxl) {
        margin-bottom: 30px;
      }
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    p {
      max-width: 80%;
      @include max($lg) {
        max-width: 100%;
      }
      @include max($sm) {
        max-width: 80%;
      }
      @include max($xxs) {
        max-width: 100%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .breadcrumbs {
      font-weight: bold;
      @include max($xxs) {
        font-size: 14px;
      }
      a {
        color: #fff;
      }
      .sep {
        margin: 0 10px;
        @include max($xxs) {
          margin: 0 5px;
        }
      }
    }
    .hero-details {
      background: $secondary;
      line-height: 1.3;
      font-size: 21px;
      padding: 5px 13px;
      display: inline-block;
      @include max($lg) {
        font-size: 17px;
        padding: 2px 7px;
      }
      .hero-position {
        font-weight: bold;
      }
    }
  }
  .img {
    width: 511px;
    flex: 0 0 511px;
    align-self: stretch;
    @include max($xxl) {
      width: 40%;
      flex: 0 0 40%;
    }
    @include max($sm) {
      width: 130vw;
      height: 110vw;
      margin: 0 -15vw;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.8;
      z-index: 0;
      flex: auto;
    }
    img {
      @include max($sm) {
        width: 124vw;
        height: 108vw;
        margin: 0 -10vw;
        flex: auto;
      }
    }
  }
  &.section-hero-v2 {
    .hero-info {
      padding: 2% 6% 4% 6%;
      @include max($sm) {
        padding: 40px 15px 40px;
      }
      h1,
      .h1 {
        margin-bottom: 40px;
        @include max($xxl) {
          margin-bottom: 30px;
        }
        @include max($lg) {
          margin-bottom: 20px;
        }
      }
    }
    .img {
      @include max($md) {
        margin-right: -20%;
        width: 60%;
        flex: 0 0 60%;
      }
      @include max($sm) {
        width: 130vw;
        margin: 0 -15vw;
      }
    }
  }
  &.section-hero-v3 {
    .hero-info {
      padding: 2% 6% 4% 6%;
      @include max($lg) {
        padding: 2% 4% 3% 4%;
      }
      @include max($sm) {
        padding: 40px 15px 40px;
      }
      h1,
      .h1 {
        font-size: 53px;
        margin-bottom: 30px;
        @include max($xxl) {
          font-size: 42px;
        }
        @include max($lg) {
          font-size: 36px;
          margin-bottom: 20px;
        }
        @include max($xxs) {
          font-size: 32px;
        }
      }
    }
    .img {
      @include max($md) {
        margin-right: -20%;
        width: 60%;
        flex: 0 0 60%;
      }
      @include max($sm) {
        width: 130vw;
        margin: -7vw -15vw 0;
      }
    }
  }
  &.section-hero-blue {
    color: #fff;
    .hero-i {
      background: url("../images/hero-blue-bg.svg") 50% 50% no-repeat;
      background-size: cover;
    }
    .hero-info {
      &:after {
        display: none;
      }
      h1,
      .h1 {
        color: $secondary;
        max-width: 55%;
        margin-bottom: 40px;
        @include max($lg) {
          margin-bottom: 20px;
        }
      }
      p {
      }
    }
  }
  &.section-hero-blue-v2 {
    color: #fff;
    .hero-i {
      background: url("../images/hero-blue-bg.svg") 50% 50% no-repeat;
      background-size: cover;
      align-items: center;

      @include max($md) {
        margin: 0 -15px;
      }
    }
    .hero-info {
      padding: 2% 6% 2% 6%;
      @include max($xxxl) {
        width: 100%;
        flex: 0 0 100%;
        min-height: 300px;
        display: flex;
        align-items: center;
        padding: 40px 20px;
      }
      @include max($lg) {
        min-height: 250px;
        padding: 40px 15px;
      }
      &:after {
        display: none;
      }
      h1,
      .h1 {
        color: $secondary;
        max-width: 80%;
        margin: 0;
        @include max($lg) {
          max-width: 100%;
        }
      }
      p {
      }
    }
  }
  &.section-hero-newsroom {
    .hero-i {
      min-height: 384px;
      @include max($xxxl) {
        min-height: 0;
      }
      h1,
      .h1 {
        @include max($sm) {
          max-width: 100%;
        }
      }
    }
  }
  &.section-hero-event {
    color: #fff;

    .hero-i {
      background: url("../images/hero-blue-bg.svg") 50% 50% no-repeat;
      background-size: cover;
      min-height: 384px;
      @include max($xxxl) {
        min-height: 0;
      }
      @include max($md) {
        margin: 0 -15px;
      }
    }
    .hero-info {
      padding: 2% 6% 4% 6%;
      @include max($xxl) {
        width: 100%;
        flex: 0 0 100%;
      }
      @include max($lg) {
        padding: 2% 4% 3% 4%;
      }
      @include max($sm) {
        padding: 40px 15px 40px;
      }
      &:after {
        display: none;
      }
      h1,
      .h1 {
        color: $secondary;
        max-width: 55%;
        margin-bottom: 30px;
        font-size: 53px;
        @include max($lg) {
          font-size: 36px;
          margin-bottom: 20px;
        }
        @include max($sm) {
          max-width: 100%;
        }
        @include max($xxs) {
          font-size: 32px;
        }
      }
      .hero-event-details {
        display: flex;
        width: 100%;
        align-items: flex-end;
        @include max($sm) {
          flex-direction: column;
          align-items: stretch;
        }
        .hero-event-info {
          flex: 1;
          margin-right: 30px;
          @include max($sm) {
            margin: 0 0 30px;
          }
          p {
            font-weight: bold;
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
        .hero-event-btns {
          margin-left: auto;
          padding-right: 3%;
          @include max($lg) {
            padding: 0;
          }
          @include max($sm) {
            margin: 0;
          }
          .btn {
          }
        }
      }
    }
  }
  &.section-hero-expert {
    .hero-info {
      @include max($sm) {
        order: 2;
        min-height: 0;
      }
      h1,
      .h1 {
        margin-bottom: 30px;
        @include max($lg) {
          margin-bottom: 20px;
        }
      }
    }
    .img {
      @include max($sm) {
        width: 130vw;
        height: 110vw;
        margin: 0 -15vw;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        position: relative;
        opacity: 1;
        order: 1;
      }
      // height: 447px;
      img {
        @include max($sm) {
          width: 124vw;
          height: 108vw;
          margin: 0 -10vw;
          flex: auto;
        }
      }
    }
  }
  &.section-hero-post {
    .hero-info {
      padding: 2% 6% 4% 6%;
      @include max($lg) {
        padding: 2% 4% 3% 4%;
      }
      @include max($sm) {
        // padding: 40px 15px 40px;
        order: 2;
        min-height: 0;
      }
      h1,
      .h1 {
        font-size: 53px;
        margin-bottom: 30px;
        @include max($xxl) {
          font-size: 42px;
        }
        @include max($lg) {
          font-size: 36px;
          margin-bottom: 20px;
        }
        @include max($xxs) {
          font-size: 32px;
        }
      }
    }
    .img {
      @include max($sm) {
        width: 130vw;
        height: 110vw;
        margin: 0 -15vw;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        position: relative;
        opacity: 1;
        order: 1;
      }
      // height: 447px;
      img {
        @include max($sm) {
          width: 124vw;
          height: 108vw;
          margin: 0 -10vw;
          flex: auto;
        }
      }
    }
  }
}

.section-info {
  padding: 40px 0 50px;
  @include max($lg) {
    padding: 30px 0;
  }
  .img {
    margin: 0 40px 0 auto;
    width: 536px;
    @include max($xxl) {
      width: 100%;
      max-width: 500px;
      margin: 0;
    }
    @include max($lg) {
      margin-bottom: 20px;
    }
  }
}

.section-gallery {
  padding: 40px 0 50px;
  @include max($lg) {
    padding: 30px 0;
  }
  h2,
  .h2 {
  }
}
.gallery-swiper-container {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 36px;
    width: 50%;
    background: url("../images/frame-orange.svg") 100% 0 no-repeat;
    background-size: contain;
    pointer-events: none;
    @include max($xs) {
      display: none;
    }
  }
  .gallery-swiper {
    position: relative;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 0;
      width: 50px;
      // padding-bottom: 7.87%;
      background: url("../images/corner.svg") 0 0 no-repeat;
      background-size: 100% auto;
      @include max($xs) {
        display: none;
      }
    }
    &:before {
      left: 0;
      transform: rotate(180deg);
      // background-position: 100% 100%;
    }
    &:after {
      right: 0;
    }
    .swiper-wrapper {
      align-items: center;
    }
  }
  .swiper-pagination {
    margin-top: 30px;
  }
  .gallery-item {
    line-height: 0;
    width: auto;
    img {
      width: 100%;
      @include max($xs) {
        max-height: 300px;
        object-fit: contain;
        // object-fit: cover;
        // object-position: top center;
      }
    }
  }
}

.section-team {
  &.section-team-1 {
    padding: 40px 0 85px;
    @include max($lg) {
      padding: 30px 0;
    }
  }
  &.section-team-2 {
    padding: 50px 0 120px;
    @include max($lg) {
      padding: 30px 0;
    }
    .team-list {
      display: flex;
      gap: 21px;
      @include max($lg) {
        flex-wrap: wrap;
      }
      .team-item {
        flex: 0 0 304px;
        @include max($lg) {
          margin-bottom: 30px;
        }
        @include max($sm) {
          flex: 0 0 100%;
        }
      }
    }
  }
  .team-swiper {
  }
}
.team-item {
  .item-photo {
    line-height: 0;
    display: block;
    margin-bottom: 32px;
    max-width: 304px;
  }
  .item-name {
    font-weight: bold;
    text-transform: uppercase;
  }
  .item-position {
  }
  .item-info {
    margin-top: 30px;
  }
  .item-location {
    text-transform: uppercase;
  }
  .item-contacts {
    margin-top: 25px;
    p {
      margin: 0;
    }
    a {
      &:hover {
      }
    }
  }
}

.section-offer {
  padding: 80px 0 90px;
  @include max($lg) {
    padding: 30px 0 10px;
  }
  background: $greyf;
  .section-h {
    margin-bottom: 80px;
    @include max($lg) {
      margin-bottom: 30px;
    }
  }
  h2,
  .h2 {
  }
  .offer-list {
  }
  .offer-item {
    @include max($lg) {
      margin-bottom: 40px;
    }
    .item-img {
      line-height: 0;
      margin-bottom: 25px;
      @include max($lg) {
        margin-bottom: 15px;
      }
      img {
      }
    }
    .item-title {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    .item-desc {
    }
  }
}

.section-jobs {
  padding: 80px 0 50px;
  @include max($lg) {
    padding: 30px 0 20px;
  }
  .section-h {
    margin-bottom: 50px;
  }
  h2,
  .h2 {
    margin-bottom: 25px;
    @include max($lg) {
      margin-bottom: 10px;
    }
  }
  .subtitle {
    margin-bottom: 50px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }

  .jobs-list {
  }
  .jobs-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .item-top {
      display: flex;
      align-items: stretch;
      min-height: 60px;
      @include max($xxs) {
        margin: 0 0 0 -15px;
      }
      .item-toggler {
        width: 66px;
        flex: 0 0 66px;
        line-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s;
        @include max($xxs) {
          width: 50px;
          flex: 0 0 50px;
        }
        &:hover {
          background: rgba($primary, 0.1);
        }
        .icon {
          transition: all 0.2s;
        }
      }
      .item-top-r {
        flex: 1;
        display: flex;
        align-items: center;
        line-height: 1.2;
        @include max($lg) {
          display: block;
          padding: 10px;
        }
      }
      .item-vacancy {
        padding: 10px 10px 10px 18px;
        font-weight: bold;
        text-transform: uppercase;
        flex: 1;
        @include max($lg) {
          padding: 0;
        }
      }
      .item-location {
        padding: 10px 10px 10px 18px;
        font-weight: bold;
        text-transform: uppercase;
        flex: 0 0 23%;
        @include max($lg) {
          padding: 0;
        }
      }
      .item-applied {
        padding: 10px 10px 10px 18px;
        flex: 0 0 23%;
        @include max($lg) {
          padding: 0;
        }
      }
    }
    .item-content {
      display: none;
      padding: 40px 0 75px;
      @include max($lg) {
        padding: 20px 0;
      }
      .item-desc {
        p {
        }
      }
    }
    &:nth-child(2n + 1) {
      .item-top {
        background: $greyf;
      }
      &.is-active {
        background: $greyf;
      }
    }
    &:nth-child(2n) {
      .item-top {
        background: $greye;
      }
      &.is-active {
        background: $greye;
      }
    }
    &.is-active {
    }
    &.is-active {
      .item-top {
        .item-toggler {
          background: $primary;
          color: #fff;
          .icon {
            transform: rotate(45deg);
          }
        }
      }
      .item-content {
        display: block;
      }
    }
  }
}

.section-traineeships {
  padding: 50px 0 40px;
  .img {
    margin: 0 40px 0 auto;
    width: 536px;
    @include max($xxl) {
      width: 100%;
      max-width: 500px;
      margin: 0;
    }
    @include max($lg) {
      margin-bottom: 20px;
    }
  }

  .txt {
  }
}
