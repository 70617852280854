/***** Components, common blocks *****/

.swiper-pagination {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  .swiper-pagination-bullet {
    width: 30px;
    height: 6px;
    background: $greye;
    cursor: pointer;
    &-active {
      background: $primary;
      cursor: default;
    }
  }
}

.tabs {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 21px;
  margin-bottom: 50px;
  @include max($lg) {
    margin-bottom: 30px;
  }
  @include max($sm) {
    gap: 10px;
    margin-bottom: 20px;
  }
  @include max(374px) {
    gap: 8px;
  }
  .tab {
    position: relative;
    text-transform: uppercase;
    padding: 16px 0;
    line-height: 1;
    width: calc(25% - 16px);
    cursor: pointer;
    @include max($lg) {
      width: auto;
      padding: 7px 15px 10px 0;
    }
    @include max($sm) {
      font-size: 14px;
      letter-spacing: -0.02em;
      padding: 5px 10px 8px 0;
    }
    @include max(374px) {
      padding: 5px 0 10px;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $primary;
    }
    &.is-active {
      &:after {
        height: 5px;
        bottom: -3px;
      }

    }
  }
}
.tabs-contents {
  .tab-content {
    display: none;
    &.is-active {
      display: block;
    }
  }
}
.filter-contents {
  .filter-content {
    display: none;
    &.is-active {
      display: block;
    }
  }
}

.search-block {
  background: $greyf;
  border-radius: 19px;
  height: 38px;
  transition: all 0.3s;
  form {
    display: flex;
    align-items: stretch;
  }
  .search-input {
    border: none;
    background: none;
    flex: 1;
    width: 68px;
    height: 38px;
    padding: 9px 10px 9px 20px;
    line-height: 20px;
    transition: all 0.2s;
    opacity: 0.7;
  
  }
  .search-btn {
    border: none;
    background: none;
    width: 38px;
    flex: 0 0 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      opacity: 0.8;
    }
    .icon {}
  }
}