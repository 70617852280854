/*** Contacts ***/

.section-offices {
  padding: 80px 0 30px;
  @include max($lg) {
    padding: 30px 0 20px;
  }
  .map {
    height: 427px;
    background: $greyf;
    margin-bottom: 60px;
    @include max($lg) {
      margin: 0 -15px 40px;
    }
  }
  .offices {
  }
  .office {
    @include max($lg) {
      margin-bottom: 30px;
    }
    .block-h {
      margin-bottom: 30px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
  }
}
.section-contactform {
  padding: 30px 0 130px;
  @include max($lg) {
    padding: 10px 0 40px;
  }

  .contact-form-b {
    display: flex;
    align-items: stretch;
    .contact-form-b-i {
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      @include max($lg) {
        padding: 0;
      }
    }
    .custom-form-desc {
      padding: 20px 0 20px 40px;
    }
    .contact-form-btn {
      margin-top: auto;
      margin-bottom: 20px;
    }
  }
}