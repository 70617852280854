/*** About Us ***/

.section-about {
  padding: 40px 0 110px;
  @include max($lg) {
    padding: 30px 0 40px;
  }
  .about-tabs {}
  .about-info {
    padding: 0 0 30px;
    .img {
      margin-right: 90px;
      @include max($lg) {
        margin: 0 0 30px;
      }
    }
  }

  .about-gallery {}

  .about-honor {
    background: $greyf;
    padding: 60px 0 90px;
    &.about-honor-1 {
      padding: 60px 0 30px;
      @include max($lg) {
        padding: 30px 0;
      }
    }
    &.about-honor-2 {
      padding: 30px 0 90px;
      @include max($lg) {
        padding: 30px 0;
      }
    }
    &.about-honor-3 {
      padding: 30px 0 90px;
      @include max($lg) {
        padding: 30px 0;
      }
    }
    .tabs {
      margin-bottom: 60px;
      @include max($lg) {
        margin-bottom: 30px;
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background: #fff;
        &.swiper-pagination-bullet-active {
          background: $primary;
        }
      }
    }
  }
}

.section-honor {
  .section-h {
    margin-bottom: 55px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }
}

.honor-card {
  background: url('../images/frame-wide.svg') 50% 50% no-repeat;
  background-size: contain;
  position: relative;
  .card-i {
    padding-bottom: 73.29%;

  }
  .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 0;
    transition: all 0.2s;
    img {}
  }
  .card-txt {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    z-index: 1;
    transition: all 0.2s;
    span {
      max-height: 100%;
      overflow: hidden;
    }
  }
  &:hover {
    .card-img {
      opacity: 0;
      visibility: hidden;
    }
    .card-txt {
      opacity: 1;
      visibility: visible;
    }
  }
}

.about-membership {
  background: $greyf;
}
.section-membership {
  padding: 60px 0 70px;
  @include max($lg) {
    padding: 30px 0;
  }
  .section-h {
    margin-bottom: 50px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }
  .membership-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 21px;
    @include max($lg) {
      gap: 30px 21px;
    }
    .membership-card {
      width: calc(25% - 16px);
      flex: 0 0 calc(25% - 16px);
      @include max($xxl) {
        width: calc(33.3333% - 14px);
        flex: 0 0 calc(33.3333% - 14px);
      }
      @include max($lg) {
        width: calc(50% - 11px);
        flex: 0 0 calc(50% - 11px);
      }
      @include max($md) {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}
.membership-card {
  background: url('../images/frame-wide.svg') 50% 50% no-repeat;
  background-size: contain;
  position: relative;
  cursor: default;
  .card-i {
    padding-bottom: 73.29%;

  }
  .card-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 0;
    transition: all 0.2s;
    img {}
  }
  .card-txt {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 20px 45px;
    z-index: 1;
    transition: all 0.2s;
    .card-txt-i {
      max-height: 100%;
      overflow: hidden;
    }
    .card-title {
      font-weight: bold;
    }
  }
  &:hover {
    .card-img {
      opacity: 0;
      visibility: hidden;
    }
    .card-txt {
      opacity: 1;
      visibility: visible;
    }
  }
}

.section-social {
  padding: 0 0 90px;
  @include max($lg) {
    padding: 0 0 40px;
  }
  .section-h {
    margin-bottom: 50px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }
  .social-txt {
    max-width: 620px;
    margin-bottom: 60px;
  }
  .partner-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    gap: 40px 0;
    .partner-item {
      text-align: center;
      width: 25%;
      flex: 0 0 25%;
      padding: 0 20px;
      @include max($xxl) {
        width: 33.3333%;
        flex: 0 0 33.3333%;
      }
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
      }
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
      }
      .item-img {
        width: 100%;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        margin-bottom: 15px;
        @include max($lg) {
          height: 140px;
        }
        img {
          max-height: 100%;
          max-width: 100%;
        }
      }
      .item-title {

        a {
          font-weight: bold;
          border-bottom: 2px solid $primary;
          &:hover {
            border-bottom-color: $primaryhover;
          }
        }
      }
    }
  }
}

.section-pro-bono {
  .section-h {
    margin-bottom: 30px;
    @include max($lg) {
      margin-bottom: 20px;
    }
  }
  .pro-bono-txt {
    max-width: 640px;
    margin-bottom: 40px;
  }
  .pro-bono-swiper {
    .swiper-slide {
      height: auto;
    }
  }
  .pro-bono-card {
    display: flex;
    align-items: stretch;
    // min-height: 183px;
    @include max($sm) {
      flex-direction: column;
    }
    .card-img {
      flex: 0 0 220px;
      background: $greye;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 60px 20px;
      @include max($sm) {
        flex: 0 0 100px;
        align-items: center;
        padding: 20px 15px;
      }
      img {}
    }
    .card-txt {
      background: $greyf;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 55px 30px 55px 60px;
      letter-spacing: 0;
      position: relative;
      @include corner-left;
      @include minmax($lg, $xxl) {
        padding: 20px 30px 20px 40px;
      }
      @include max($sm) {
        padding: 20px;
      }
      &:before {
        @include max($sm) {
          display: none;
        }
      }
      span {

      }
    }
  }
}

.about-recognitions {}
.section-recognitions {
  .recognition-featured {
    margin-bottom: 30px;
    .block-h {
      margin-bottom: 40px;
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
    .recognition-featured-item {
      display: flex;
      align-items: stretch;
      .item-txt {
        flex: 1;
        background: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 21px;
        color: #fff;
        font-weight: bold;
        padding: 60px;
        letter-spacing: 0;
        position: relative;
        
        @include max($lg) {
          padding: 30px;
          font-size: 17px;
        }
        @include max($sm) {
          font-weight: normal;
          font-size: 15px;
          padding: 20px;
        }
        span {}
      }
      .item-img {
        flex: 0 0 430px;
        background: $greyf;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        @include corner-left;
        @include max($lg) {
          flex: 0 0 30%;
          padding: 20px;
        }
        @include max($sm) {
          flex: 0 0 35%;
          padding: 20px 15px 20px 10px;
        }
        img {}
      }
    }
  }
  .recognitions {
    .recognitions-filter {
      margin-bottom: 40px;
    }
  }
}