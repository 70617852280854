/***** Footer *****/

.footer {
  .footer-message {
    background: $secondary;
    padding: 60px 0;
    @include max($lg) {
      padding: 30px 0;
    }
    .message-i {
      display: flex;
      align-items: center;
      @include max($lg) {
        flex-direction: column;
      }
    }
    .message-txt {
      font-size: 31px;
      line-height: 1.1;
      font-weight: bold;
      flex: 1;
      margin-right: 30px;
      @include max($lg) {
        text-align: center;
        margin: 0 0 20px;
        font-size: 28px;
      }
    }
    .message-btn {
      margin-left: auto;
      padding-right: 110px;
      @include max($lg) {
        margin: 0;
        padding: 0;
      }
      .btn {}
    }
  }
  .footer-b {
    background: $primary;
    color: #fff;
    padding: 85px 0;
    @include max($lg) {
      padding: 30px 0;
    }
    .footer-b-i {
      display: flex;
      align-items: flex-start;
      @include max($lg) {
        flex-wrap: wrap;
      }
      @include max($sm) {
        display: block;
      }
    }
    .footer-logo {
      width: 25%;
      flex: 0 0 25%;
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
      }
      @include max($sm) {
        width: 100%;
      }
      img {
        width: 200px;
        @include max($lg) {
          width: 150px;
          margin-bottom: 30px;
        }
      }
    }
    .footer-menu {
      width: 25%;
      flex: 0 0 25%;
      padding: 15px 0 10px;
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
        padding: 0 0 40px;
      }
      @include max($sm) {
        width: 100%;
      }
      ul {
        @include bordered-list;
        li {
          a {
            color: #fff;
            &:after {
              background-color: #fff;
            }
            &:hover {
              color: #fff;
              opacity: 0.8;
              &:after {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
    .footer-social {
      width: 15%;
      flex: 0 0 15%;
      align-self: end;
      display: flex;
      align-items: center;
      gap: 18px;
      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
        align-self: start;
        margin-bottom: 30px;
      }
      @include max($sm) {
        width: 100%;
      }
      a {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          &.icon-linkedin {
            width: 16px;
            height: 15px;
          }
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .footer-subscribe {
      width: 192px;
      flex: 0 0 192px;
      align-self: end;
      margin: 0 110px 0 auto;

      @include max($lg) {
        width: 50%;
        flex: 0 0 50%;
        align-self: start;
      }
      @include max($sm) {
        width: 100%;
      }
      .subscribe-txt {
        margin-bottom: 25px;
        @include max($lg) {
          margin-bottom: 10px;
        }
      }
      .subscribe-btn {
        .btn {}
      }
    }
  }
}
