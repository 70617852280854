/**** Form elements *****/

.form-group {
  margin-bottom: 20px;
}
.form-control {
  border: 1px solid transparent;
  background: $greyf;
  border-radius: 20px;
  transition: all 0.3s;
  display: block;
  width: 100%;
  padding: 9px 25px;
  line-height: 20px;
  font-size: 17px;
}
[type="text"].form-control,
[type="email"].form-control,
[type="password"].form-control {
  height: 40px;
}
textarea.form-control {
  resize: none;
}

.select-container {
  position: relative;
  border-bottom: 5px solid $primary;
  background: #fff;
  max-width: 100%;
  overflow: hidden;
  height: 53px;
  display: flex;
  align-items: stretch;
  transition: all 0.2s;
  @include max($lg) {
    height: 43px;
    border-bottom-width: 2px;
  }
  &.focused {
  }
  @include max($xxxs) {
    width: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 22px;
    width: 17px;
    height: 10px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 958.99 591.34"><path d="M0,111.76,479.58,591.34,959,111.92,847.11,0,479.45,367.69,111.76,0Z" fill="%230057B7"/></svg>')
      50% 50% no-repeat;
    background-size: contain;
    pointer-events: none;
    @include max($lg) {
      top: 18px;
    }
  }
  select {
    appearance: none;
    border: none;
    background: none;
    font-size: 17px;
    font-weight: normal;
    padding: 10px 30px 10px 0;
    height: 53px;
    line-height: 26px;
    width: 100%;
    text-transform: uppercase;
    color: $primary;
    @include max($lg) {
      padding: 5px 30px 5px 0;
      height: 43px;
    }
    option {
      background: $greyf;
    }
  }
}
.select-control {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  border: 1px solid transparent;
  background: $greyf;
  border-radius: 20px;
  height: 40px;
  display: flex;
  align-items: stretch;
  transition: all 0.2s;
  z-index: 1;
  outline: none;
  &.focused {
  }
  @include max($xxxs) {
    width: 100%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 17px;
    top: 16px;
    width: 17px;
    height: 10px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 958.99 591.34"><path d="M0,111.76,479.58,591.34,959,111.92,847.11,0,479.45,367.69,111.76,0Z" fill="%230057B7"/></svg>')
      50% 50% no-repeat;
    background-size: contain;
    pointer-events: none;
    z-index: 0;
  }
  .select-control:focus-within:after {
    pointer-events: none;
    outline: 2px solid transparent;
  }
  select {
    appearance: none;
    border: none;
    background: none;
    font-size: 17px;
    font-weight: normal;
    padding: 4px 45px 6px 25px;
    height: 40px;
    line-height: 20px;
    width: 100%;
    color: $primary;
    option {
      background: $greyf;
    }
  }
  p {
    width: 100%;
    margin: 0;
  }
  .wpcf7-form-control-wrap {
    display: block;
  }
}

// .form-check {
//   margin-bottom: 10px;
// }
.custom-checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    + span {
      position: relative;
      padding: 0 0 0 40px;
      display: block;
      &:before {
        content: "";
        border-radius: 6px;
        background: $greyf;
        width: 29px;
        height: 29px;
        position: absolute;
        left: 0;
        top: -2px;
        box-sizing: border-box;
      }
    }
    &:checked {
      + span {
        &:before {
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 5px;
          left: 5px;
          width: 19px;
          height: 14px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7.96"><path d="M4.27,5,8.93.33a1.23,1.23,0,0,1,1.74,1.74L5.14,7.6a1.23,1.23,0,0,1-1.74,0L.33,4.52A1.23,1.23,0,0,1,2.07,2.79Z" fill="%230057B7"/></svg>');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    &:focus {
      + span {
        &:before {
          background: #f1f0f4;
        }
      }
    }
    &:not(:disabled) {
      span {
        &:hover {
          &:before {
          }
        }
      }
    }
  }
}

// .custom-radio {
//   input[type="radio"] {
//     position: absolute;
//     opacity: 0;
//     z-index: -1;
//     + span {
//       position: relative;
//       padding: 0 0 0 28px;
//       display: block;
//       &:before {
//         content: '';
//         display: block;
//         border: 1px solid #000;
//         border-radius: 50%;
//         background: #E5E5E5;
//         box-shadow: 1px 1px 0 #A6A6A6 inset;
//         width: 16px;
//         height: 16px;
//         position: absolute;
//         left: 0;
//         top: 2px;
//         box-sizing: border-box;
//       }
//       &:after {
//         content: "";
//         display: none;
//         position: absolute;
//         left: 4px;
//         top: 6px;
//         width: 8px;
//         height: 8px;
//         background: $red;
//         border-radius: 50%;
//         z-index: 1;
//       }
//     }
//     &:checked {
//       + span {
//         &:before {

//         }
//         &:after {
//           display: block;
//         }
//       }
//     }
//     &:focus {
//       + span {
//         &:before {
//           background: #f5f5f5;
//         }
//       }
//     }
//     &:not(:disabled) {
//       span {
//         &:hover {
//           &:before {
//             background: #f5f5f5;
//           }
//         }
//       }
//     }
//   }

//   input[type="radio"] {
//     & + span.radio-info {
//       font-weight: normal;
//       &:before {
//         top: 4px;
//       }
//       &:after {
//         top: 8px;
//       }
//       .radio-info-h {
//         display: flex;
//         align-items: center;
//         margin-bottom: 5px;
//         color: #000;
//         font-size: 19px;
//         font-weight: normal;
//         line-height: 24px;
//         span {}
//         i.icon {
//           margin-left: 7px;
//         }
//       }
//       .radio-info-desc {
//         display: block;
//         font-size: 16px;
//         color: $dgrey;
//       }
//     }
//   }
// }
